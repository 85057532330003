// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig } from './serviceManager'

export default {

  async getAllListAsync(id, config) {
    const response = await axios.get('/core/reporting/asup-group/all', buildAxiosRequestConfig(config))
    return response
  },

  async getAsync(id, config) {
    const response = await axios.get(`/core/reporting/asup-group/${id}`, buildAxiosRequestConfig(config))
    return response
  },

  async createAsync(data, config) {
    const response = await axios.post('/core/reporting/asup-group', data, buildAxiosRequestConfig(config))
    return response
  },

  async createAssetAsync(id, data, config) {
    const response = await axios.post(`/core/reporting/asup-group/${id}/asset`, data, buildAxiosRequestConfig(config))
    return response
  },

  async updateAsync(id, data, config) {
    const response = await axios.put(`/core/reporting/asup-group/${id}`, data, buildAxiosRequestConfig(config))
    return response
  },

  async updateAssetAsync(id, assetId, data, config) {
    const response = await axios.put(`/core/reporting/asup-group/${id}/asset/${assetId}`, data, buildAxiosRequestConfig(config))
    return response
  },

  async deleteAsync(id, config) {
    const response = await axios.delete(`/core/reporting/asup-group/${id}`, buildAxiosRequestConfig(config))
    return response
  },

  async deleteAssetAsync(id, assetId, config) {
    const response = await axios.delete(`/core/reporting/asup-group/${id}/asset/${assetId}`, buildAxiosRequestConfig(config))
    return response
  },

  async triggerAsync(id, config) {
    const response = await axios.post(`/core/reporting/asup-group/${id}/trigger`, {}, buildAxiosRequestConfig(config))
    return response
  },

}
