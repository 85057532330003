<template>
  <div>
    <b-overlay :show="isSaving">
      <b-card>
        <b-card-header>
          <b-card-title>{{ asupGroup.displayName }}</b-card-title>
          <b-button-group>
            <b-button
              size="sm"
              @click="trigger"
            >
              Trigger
            </b-button>
            <b-button
              size="sm"
              @click="$refs.updateModal.show(asupGroup.id)"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
            <b-button
              variant="danger"
              size="sm"
              @click="deleteGroup"
            >
              <feather-icon icon="DeleteIcon" />
            </b-button>
          </b-button-group>
        </b-card-header>
        <b-card-body>
          <div class="clearfix">
            <span class="float-left mr-2">{{ formattedScheduleTime }} ({{ asupGroup.timeZone ? asupGroup.timeZone : 'UTC' }})</span>
            <b-form-checkbox
              v-model="asupGroup.scheduleMonday"
              inline
              disabled
            >
              Monday
            </b-form-checkbox>
            <b-form-checkbox
              v-model="asupGroup.scheduleTuesday"
              inline
              disabled
            >
              Tuesday
            </b-form-checkbox>
            <b-form-checkbox
              v-model="asupGroup.scheduleWednesday"
              inline
              disabled
            >
              Wednesday
            </b-form-checkbox>
            <b-form-checkbox
              v-model="asupGroup.scheduleThursday"
              inline
              disabled
            >
              Thursday
            </b-form-checkbox>
            <b-form-checkbox
              v-model="asupGroup.scheduleFriday"
              inline
              disabled
            >
              Friday
            </b-form-checkbox>
            <b-form-checkbox
              v-model="asupGroup.scheduleSaturday"
              inline
              disabled
            >
              Saturday
            </b-form-checkbox>
            <b-form-checkbox
              v-model="asupGroup.scheduleSunday"
              inline
              disabled
            >
              Sunday
            </b-form-checkbox>
          </div>

          <div class="table-responsive">
            <table class="table table-sm table-bordered mt-1">
              <thead>
                <tr>
                  <th colspan="4">
                    System
                  </th>
                  <th colspan="3">
                    Options
                  </th>
                </tr>
                <tr>
                  <th>System</th>
                  <th>Account</th>
                  <th>Organization Unit</th>
                  <th>Product family</th>
                  <th>Extended options</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="groupAsset in orderedAndValidAssets"
                  :key="groupAsset.asset.id"
                >
                  <td>{{ getAssetDisplayName(groupAsset.asset) }}</td>
                  <td>{{ groupAsset.asset.accountName }} </td>
                  <td>{{ groupAsset.asset.ouDisplayName }} </td>
                  <td>{{ groupAsset.asset.productFamily }} </td>
                  <td style="min-width: 480px">
                    <template v-if="groupAsset.options">
                      <table class="table table-borderless table-sm extendedOptionsTable">
                        <tbody>
                          <tr>
                            <th>Show if status is at least</th>
                            <td>{{ getStatusOptionText(groupAsset.options.showIfStatusIsAtLeast) }}</td>
                          </tr>
                          <tr v-if="assetSupportsClientMatrix(groupAsset.asset)">
                            <th>Include client matrix</th>
                            <td>{{ groupAsset.options.includeClientMatrix }}</td>
                          </tr>
                          <tr v-if="assetSupportsClientMatrix(groupAsset.asset)">
                            <th>Hide succeeded clients</th>
                            <td>{{ groupAsset.options.showMatrixRowsWithClientErrorsOnly }}</td>
                          </tr>
                          <tr v-if="assetSupportsClientMatrix(groupAsset.asset)">
                            <th>Hide groups in matrix</th>
                            <td>{{ groupAsset.options.hideMatrixGroups }}</td>
                          </tr>
                          <tr v-if="assetSupportsClientMatrix(groupAsset.asset)">
                            <th>Hide system summary</th>
                            <td>{{ groupAsset.options.hideSystemSummary }}</td>
                          </tr>
                          <tr v-if="assetSupportsClientMatrix(groupAsset.asset)">
                            <th>Matrix day range</th>
                            <td>{{ groupAsset.options.matrixDayRange }} days</td>
                          </tr>
                          <tr v-if="assetSupportsClientMatrix(groupAsset.asset) && groupAsset.options.groupFilterRegex">
                            <th>Group Filter</th>
                            <td>{{ groupAsset.options.groupFilterRegex }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </template>
                  </td>
                  <td>
                    <b-button-group
                      size="sm"
                    >
                      <b-button
                        variant="secondary"
                        @click="$refs.configModal.show(asupGroup.id, groupAsset.id)"
                      >
                        <feather-icon icon="ToolIcon" />
                      </b-button>
                      <b-button
                        variant="danger"
                        @click="deleteAsset(groupAsset.asset.id)"
                      >
                        <feather-icon icon="DeleteIcon" />
                      </b-button>
                    </b-button-group>
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="10"
                  >
                    <b-button
                      size="sm"
                      class="mt-1 mb-1"
                      @click="$refs.assetSelectModal.show()"
                    >
                      <feather-icon icon="PlusIcon" /> Add system
                    </b-button>
                  </td>
                </tr>
              </tbody></table>
          </div>
        </b-card-body>
      </b-card>
      <AsupGroupAssetSelectModal
        ref="assetSelectModal"
        :hide-asset-ids="assetIds"
        @assets-selected="onAssetsSelected"
      />
      <AsupGroupCreateUpdateModal
        ref="updateModal"
        @asup-group-updated="$emit('updated')"
      />
      <AsupGroupItemConfigModal
        ref="configModal"
        @updated="$emit('updated')"
      />

    </b-overlay>
  </div>
</template>

<script>

import {
  BCard, BCardHeader, BCardBody, BCardTitle, BFormCheckbox, BButton, BButtonGroup, BOverlay,
} from 'bootstrap-vue'
import AsupGroupAssetSelectModal from './AsupGroupAssetSelectModal.vue'
import AsupGroupReportingService from '@/service/asupGroupReporting.service'
import AsupGroupCreateUpdateModal from './AsupGroupCreateUpdateModal.vue'
import AsupGroupItemConfigModal from './AsupGroupItemConfigModal.vue'
import { productFamilySupportsClientMatrix } from '@/utils/helpers'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BFormCheckbox,
    BButton,
    BButtonGroup,
    BOverlay,
    AsupGroupAssetSelectModal,
    AsupGroupCreateUpdateModal,
    AsupGroupItemConfigModal,
  },
  props: {
    asupGroup: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
    }
  },
  computed: {
    showStatusAtLeastOptions() {
      return [
        { value: 0, text: 'Ok' },
        { value: 10, text: 'Info' },
        { value: 20, text: 'Warning' },
        { value: 30, text: 'Error' },
      ]
    },
    orderedAndValidAssets() {
      if (this.asupGroup && this.asupGroup.assets) {
        return this.asupGroup.assets
          .filter(x => x.asset !== null)
          .concat()
          .sort((a, b) => (this.getAssetDisplayName(a.asset) > this.getAssetDisplayName(b.asset) ? 1 : -1))
      }

      return []
    },
    formattedScheduleTime() {
      if (this.asupGroup.scheduleTime) {
        return this.$moment.utc(this.asupGroup.scheduleTime, 'HH:mm:ss').format('HH:mm')
      }

      return 'N/A'
    },
    assetIds() {
      if (this.asupGroup.assets) {
        return this.asupGroup.assets
          .filter(x => x.asset !== null)
          .map(x => x.asset.id)
      }
      return []
    },
  },
  methods: {
    getStatusOptionText(value) {
      const option = this.showStatusAtLeastOptions.find(x => x.value === value)
      if (option) {
        return option.text
      }

      return ''
    },
    assetSupportsClientMatrix(asset) {
      if (!asset) {
        return false
      }

      return productFamilySupportsClientMatrix(asset.productFamily, asset.model)
    },
    async onAssetsSelected(event) {
      this.isSaving = true
      if (event.selectedAssetIds) {
        for (let i = 0; i < event.selectedAssetIds.length; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          await AsupGroupReportingService.createAssetAsync(this.asupGroup.id, {
            assetId: event.selectedAssetIds[i],
            options: {
              includeClientMatrix: true,
              matrixDayRange: 7,
            },
          })
        }

        this.isSaving = false

        this.$emit('updated')
      }
    },
    deleteAsset(assetId) {
      this.isSaving = true
      AsupGroupReportingService.deleteAssetAsync(this.asupGroup.id, assetId)
        .finally(() => {
          this.$emit('updated')
          this.isSaving = false
        })
    },
    getAssetDisplayName(asset) {
      if (asset.hostname) {
        return asset.hostname
      }

      return asset.systemNumber
    },
    deleteGroup() {
      const self = this
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          self.isSaving = true
          AsupGroupReportingService.deleteAsync(self.asupGroup.id)
            .then(deleteResult => {
              this.$toast('Your reporting group has been deleted.')
              this.asupGroup = deleteResult
              this.$emit('deleted')
            })
        }
      })
    },
    trigger() {
      this.isSaving = true
      AsupGroupReportingService.triggerAsync(this.asupGroup.id)
        .then(() => {
          this.$toast('Report has been triggered.')
        })
        .finally(() => {
          this.isSaving = false
        })
    },
  },
}

</script>

<style scoped>
  .extendedOptionsTable th,
  .extendedOptionsTable td {
    padding: 0;
  }
</style>
