<template>
  <b-modal
    id="asup-group-asset-select-modal"
    v-model="isShow"
    title="Add Asset"
    no-close-on-backdrop
    size="xl"
    scrollable
    ok-title="Select"
    @ok="save"
  >
    <b-form-input
      v-model="filter"
      placeholder="Search..."
      class="mb-1"
    />

    <b-overlay :show="isAssetListLoading">
      <div class="table-responsive">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>System</th>
              <th>Organization unit</th>
              <th>Product family</th>
              <th>Account</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="asset in assetResult"
              :key="asset.id"
            >
              <td>
                <b-form-checkbox
                  class="ml-1"
                  @change="onChangeSelection(asset.id, $event)"
                > {{ getAssetDisplayName(asset) }}
                </b-form-checkbox>
              </td>
              <td>{{ (asset.organizationUnit || {}).displayName }}</td>
              <td>{{ asset.productFamily }}</td>
              <td>{{ (asset.account || {}).name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>

import {
  BModal, BOverlay, BFormCheckbox, BFormInput,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BModal,
    BOverlay,
    BFormCheckbox,
    BFormInput,
  },
  props: {
    hideAssetIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isShow: false,
      filter: null,
      selectedAssetIds: [],
    }
  },
  computed: {
    ...mapGetters({
      isAssetListLoading: 'assetList/isLoading',
      isHost: 'auth/isHost',
    }),
    assetResult() {
      let assets = this.$store.getters['assetList/assets']

      // filter assets valid to add to asup report group
      // only active assets with ASUP and with specific product family
      assets = assets.filter(
        x => x.asupSystemId !== null && x.status === 1,
      )

      // filter assets to hide
      assets = assets.filter(x => !this.hideAssetIds.includes(x.id))

      // quick search
      if (this.filter) {
        return assets.filter(
          x => (x.productFamily && x.productFamily.toLowerCase().includes(this.filter.toLowerCase()))
            || (x.hostname && x.hostname.toLowerCase().includes(this.filter.toLowerCase()))
            || (x.systemNumber && x.systemNumber.toLowerCase().includes(this.filter.toLowerCase()))
            || (x.account && x.account.name && x.account.name.toLowerCase().includes(this.filter.toLowerCase())),
        )
      }

      return assets
    },
  },
  methods: {
    show() {
      this.filter = null
      this.isShow = true
      this.selectedAssetIds = []

      const config = {
        options: {
          includeDefaultOu: true,
        },
        requestConfig: {
          disableTenantFilter: true,
        },
      }
      this.$store.dispatch('assetList/fetchAllAssets', config)
    },
    getAssetDisplayName(asset) {
      if (asset.hostname) {
        return asset.hostname
      }

      return asset.systemNumber
    },
    onChangeSelection(assetId, selected) {
      if (selected === false) {
        this.selectedAssetIds = this.selectedAssetIds.filter(x => x !== assetId)
      } else {
        this.selectedAssetIds.push(assetId)
      }
    },
    save() {
      this.$emit('assets-selected', {
        selectedAssetIds: this.selectedAssetIds,
      })
    },
  },
}
</script>
