<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      <b-form
        @submit.stop.prevent="handleSubmit(onSubmit)"
      >
        <b-modal
          id="create-asup-group"
          v-model="isShow"
          :title="isCreateMode ? 'Create report group' : 'Update report group'"
          no-close-on-backdrop
          @ok="save"
        >
          <b-overlay :show="isLoading || isSaving">
            <validation-provider
              v-slot="validationContext"
              name="Name"
              :rules="{ required: true, max: 100 }"
            >
              <b-form-group
                label="Name:"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="form.displayName"
                  name="input-name"
                  placeholder="Enter name"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-name-live-feedback"
                />
                <b-form-invalid-feedback id="input-name-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <hr>

            <b-form-group
              label="Schedule on:"
            >
              <b-row align-v="center">
                <b-col>
                  <b-time
                    v-model="form.scheduleTime"
                    :minutes-step="10"
                    :hour12="false"
                  />
                </b-col>
                <b-col>
                  <b-button
                    size="sm"
                    class="mb-1"
                    @click="selectAllDays"
                  >
                    Select all days
                  </b-button>
                  <b-form-checkbox v-model="form.scheduleMonday">
                    Monday
                  </b-form-checkbox>
                  <b-form-checkbox v-model="form.scheduleTuesday">
                    Tuesday
                  </b-form-checkbox>
                  <b-form-checkbox v-model="form.scheduleWednesday">
                    Wednesday
                  </b-form-checkbox>
                  <b-form-checkbox v-model="form.scheduleThursday">
                    Thursday
                  </b-form-checkbox>
                  <b-form-checkbox v-model="form.scheduleFriday">
                    Friday
                  </b-form-checkbox>
                  <b-form-checkbox v-model="form.scheduleSaturday">
                    Saturday
                  </b-form-checkbox>
                  <b-form-checkbox v-model="form.scheduleSunday">
                    Sunday
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-group>

            <b-link @click="showEditTimezone = !showEditTimezone">
              {{ form.timeZone ? form.timeZone : 'Timezone UTC' }}
            </b-link>

            <b-form-group v-show="showEditTimezone">
              <validation-provider
                v-slot="validationContext"
                name="timezone"
                :rules="{ required: true }"
              >
                <v-select
                  v-model="form.timeZone"
                  size="sm"
                  :options="timezoneOptions"
                  :reduce="timezone => timezone.value"
                  label="text"
                  name="timezone"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-timezone-live-feedback"
                  @option:selected="showEditTimezone = false"
                />
                <b-form-invalid-feedback id="input-timezone-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <hr>

            <b-alert
              variant="info"
              show
            >
              <div class="alert-body">
                <span>Email will be sent to <strong>{{ userProfile.email }}</strong></span>
              </div>
            </b-alert>

            <b-form-group
              label="Email CC:"
              description="Send the report to another email as CC. Please be aware that the report may contain sensitive information."
            >

              <b-input-group
                v-for="(cc, index) in form.cc"
                :key="index"
              >
                <b-form-input
                  v-model="form.cc[index]"
                  placeholder="email@email.de"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-danger"
                    @click="form.cc.splice(index, 1)"
                  >
                    X
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <b-button
                v-if="form.cc.length < 5"
                size="sm"
                class="mt-1 mb-1"
                @click="addCCEmail"
              >
                <feather-icon icon="PlusIcon" /> Add email
              </b-button>
            </b-form-group>

          </b-overlay>
        </b-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>

import {
  BRow, BCol, BModal, BFormCheckbox, BOverlay, BFormInput, BFormGroup, BFormInvalidFeedback, BForm, BTime, BButton, BInputGroup, BInputGroupAppend, BLink, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  required, email, max, mapServerFieldValidationErrors,
} from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'
import moment from 'moment-timezone'
import AsupGroupReportingService from '@/service/asupGroupReporting.service'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BFormCheckbox,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
    BTime,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
    BLink,
    BAlert,
    vSelect,
  },
  data() {
    return {
      asupGroupId: null,
      isShow: false,
      isSaving: false,
      isLoading: false,
      form: {
        displayName: '',
        scheduleTime: '',
        cc: [],
        scheduleMonday: false,
        scheduleTuesday: false,
        scheduleWednesday: false,
        scheduleThursday: false,
        scheduleFriday: false,
        scheduleSaturday: false,
        scheduleSunday: false,
        timeZone: null,
      },
      showEditTimezone: false,
    }
  },
  computed: {
    ...mapGetters({
      userProfile: 'auth/userProfile',
    }),
    isCreateMode() {
      return !this.asupGroupId
    },
    timezoneOptions() {
      const result = [{
        value: null,
        text: 'Please select your timezone',
      }]

      const tzOptions = moment.tz
        .names()
        .map(name => {
          const now = Date.now()
          const zone = moment.tz.zone(name)
          return { name, offset: zone !== null ? zone.utcOffset(now) : 0 }
        })
        .sort((a, b) => (a.offset === b.offset
          ? a.name.localeCompare(b.name)
          : b.offset - a.offset))
        .map(zone => {
          const gmtOffset = `GMT${moment.tz(zone.name).format('Z')}`
          return {
            value: zone.name,
            text: `(${gmtOffset}) ${zone.name}`,
          }
        })

      result.push(...tzOptions)

      return result
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    show(asupGroupId) {
      this.resetForm()
      this.isShow = true

      this.asupGroupId = asupGroupId

      if (!this.isCreateMode) {
        this.isLoading = true
        AsupGroupReportingService.getAsync(this.asupGroupId)
          .then(result => {
            const asupGroup = result.data
            this.form = {
              displayName: asupGroup.displayName,
              scheduleTime: this.$moment.utc(asupGroup.scheduleTime, 'HH:mm:ss').format('HH:mm'),
              cc: asupGroup.cc,
              scheduleMonday: asupGroup.scheduleMonday,
              scheduleTuesday: asupGroup.scheduleTuesday,
              scheduleWednesday: asupGroup.scheduleWednesday,
              scheduleThursday: asupGroup.scheduleThursday,
              scheduleFriday: asupGroup.scheduleFriday,
              scheduleSaturday: asupGroup.scheduleSaturday,
              scheduleSunday: asupGroup.scheduleSunday,
              timeZone: asupGroup.timeZone,
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      } else if (!this.form.timeZone) {
        this.form.timeZone = this.userProfile.timeZone
      }
    },
    resetForm() {
      this.form = {
        displayName: '',
        scheduleTime: '08:00:00',
        cc: [],
        scheduleMonday: false,
        scheduleTuesday: false,
        scheduleWednesday: false,
        scheduleThursday: false,
        scheduleFriday: false,
        scheduleSaturday: false,
        scheduleSunday: false,
        timeZone: null,
      }
    },
    save() {
      this.isSaving = true

      if (this.isCreateMode) {
        AsupGroupReportingService.createAsync(this.form)
          .then(
            response => {
              this.$emit('asup-group-created', {
                role: response.data,
              })

              this.$toast.success('Reporting Group has been created.', {
                icon: true,
              })

              this.isShow = false
            },
            err => {
              this.$swal({
                title: 'Something went wrong!',
                text: err.response.data.error.message,
                icon: 'error',
              })
            },
          )
          .finally(() => {
            this.isSaving = false
          })
      } else {
        AsupGroupReportingService.updateAsync(this.asupGroupId, this.form)
          .then(
            response => {
              this.$emit('asup-group-updated', {
                role: response.data,
              })

              this.$toast.success('Reporting Group has been updated.', {
                icon: true,
              })

              this.isShow = false
            },
            err => {
              this.$swal({
                title: 'Something went wrong!',
                text: err.response.data.error.message,
                icon: 'error',
              })
            },
          )
          .finally(() => {
            this.isSaving = false
          })
      }
    },
    addCCEmail() {
      if (this.form.cc.length < 5) {
        this.form.cc.push('')
      }
    },
    selectAllDays() {
      this.form.scheduleMonday = true
      this.form.scheduleTuesday = true
      this.form.scheduleWednesday = true
      this.form.scheduleThursday = true
      this.form.scheduleFriday = true
      this.form.scheduleSaturday = true
      this.form.scheduleSunday = true
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
