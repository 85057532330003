function productFamilySupportsClientMatrix(productFamily, model) {
  switch (productFamily) {
    case 'NetWorker':
    case 'Avamar':
    case 'PPDM':
      return true
    case 'Veeam Software':
      if (model === 'Backup + Replication') {
        return true
      }
      return false
    default:
      return false
  }
}

export {
  // eslint-disable-next-line import/prefer-default-export
  productFamilySupportsClientMatrix,
}
