<template>
  <div>
    <portal to="breadcrumb-right">
      <b-button
        size="sm"
        @click="createGroup"
      >
        <feather-icon
          icon="PlusIcon"
        /> Create
      </b-button>
    </portal>

    <b-alert
      v-if="userProfile.emailConfirmed === false"
      variant="danger"
      show
    >
      <div class="alert-body">
        <span>
          You will not receive any report emails because your E-Mail address has not been not confirmed yet.
          <b-link
            class="alert-link"
            :to="{ name: 'account-setting'}"
          >
            Click here to confirm it now.
          </b-link>
        </span>
      </div>
    </b-alert>

    <b-overlay :show="isLoading">
      <asup-group-item-view
        v-for="asupGroup in asupGroups"
        :key="asupGroup.id"
        :asup-group="asupGroup"
        @deleted="loadData"
        @updated="loadData"
      />
    </b-overlay>

    <AsupGroupCreateUpdateModal
      ref="createModal"
      @asup-group-created="loadData"
    />
  </div>
</template>

<script>

import {
  BOverlay, BButton, BAlert, BLink,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import AsupGroupItemView from './AsupGroupItemView.vue'
import AsupGroupCreateUpdateModal from './AsupGroupCreateUpdateModal.vue'
import AsupGroupReportingService from '@/service/asupGroupReporting.service'

export default {
  components: {
    BOverlay,
    AsupGroupItemView,
    AsupGroupCreateUpdateModal,
    BButton,
    BAlert,
    BLink,
  },
  data() {
    return {
      asupGroups: [],
      isLoading: false,
    }
  },
  mounted() {
    this.loadData()
  },
  computed: {
    ...mapGetters({
      userProfile: 'auth/userProfile',
    }),
  },
  methods: {
    loadData() {
      this.isLoading = true
      AsupGroupReportingService.getAllListAsync()
        .then(result => {
          this.asupGroups = result.data.items
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    createGroup() {
      this.$refs.createModal.show()
    },
  },
}

</script>
